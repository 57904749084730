<template>
  <b-card-code title="جدول الوظائف" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن .."
            />
            <b-input-group-append>
              <b-button @click="resetSearch()"> Clear </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة وظيفة جديدة</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        
      </div>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
      <template #cell(image)="image">
        <b-avatar size="lg" :src="getImage(image.item.image)" />
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل وظيفة"
      @show="checkModel"
      @ok="uM"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_title"
              :state="editform.ar_title.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.en_title"
              :state="editform.en_title.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">تفاصيل مختصرة بالعربي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.ar_sub_description"
              :state="editform.ar_sub_description.length > 0"
              placeholder="تفاصيل مختصرة بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">تفاصيل مختصرة بالانكليزي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.en_sub_description"
              :state="editform.en_sub_description.length > 0"
              placeholder="تفاصيل مختصرة بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">تفاصيل بالعربي:</label>
            <quill-editor
              ref="editformar_description"
              v-model="editform.ar_description"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">تفاصيل بالانكليزي:</label>
            <quill-editor
              ref="editformen_description"
              v-model="editform.en_description"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">المهارات:</label>
            <quill-editor
              ref="editformskills"
              v-model="editform.skills"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر صورة:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="editform.file"
              @change="selectFileForEditForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="اضافة وظيفة جديدة"
      centered
      size="lg"
      title="حفظ البيانات"
      @show="checkModel"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.ar_title"
              :state="form.ar_title.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.en_title"
              :state="form.en_title.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">تفاصيل مختصرة بالعربي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="form.ar_sub_description"
              :state="form.ar_sub_description.length > 0"
              placeholder="تفاصيل مختصرة بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">تفاصيل مختصرة بالانكليزي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="form.en_sub_description"
              :state="form.en_sub_description.length > 0"
              placeholder="تفاصيل مختصرة بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">تفاصيل بالعربي:</label>
            <quill-editor
              ref="formar_description"
              v-model="form.ar_description"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">تفاصيل بالانكليزي:</label>
            <quill-editor
              ref="formen_description"
              v-model="form.en_description"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">required المهارات:</label>
            <quill-editor
              ref="form.skills"
              v-model="form.skills"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر الصورة:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="form.file"
              @change="selectFileForForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="حذف وظيفة"
      @ok="dM"
      ok-title="حذف الحقل"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل  " {{ this.editform.ar_title }} "
        ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { filesUrl } from "@/main.js";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BFormTags,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,BProgress,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    quillEditor,
    vSelect,
    BAvatar,BProgress,
    BBadge,
    BFormTags,
    BFormRow,
    BDropdown,
    filesUrl,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormFile,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      department: "",
      title: "",
      filter: null,
      filterOn: [],
      departments: [],
      teachers: [],
      stages: [],
      fields: [
        {
          key: "ar_title",
          label: "العنوان بالعربي",
        },
        {
          key: "en_title",
          label: "العنوان بالانكليزي",
        },
        { key: "user.full_name", label: "الناشر " },
        { key: "views", label: "المشاهدات " },
        { key: "image", label: "الصورة " },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      form: {
        en_title: "",
        ar_title: "",
        file: "",
        ar_description: "",
        en_description: "",
        ar_sub_description: "",
        en_sub_description: "",
        skills:"",
        tags: [],
      },
      editform: {
        en_title: "",
        ar_title: "",
        file: "",
        ar_description: "",
        en_description: "",
        ar_sub_description: "",
        en_sub_description: "",
        skills:"",
        id: null,
      },
      selected: [],
      selected2: "",
      formModal: {},
      snowOption: {
        theme: "snow",
      },
      slider:"",
    };
  },
  watch: {
    department: function (val) {
      this.gS();
    },
    title: function (val) {
      this.gS();
    },
    slider: function (val) {
      this.gS();
    },
    "editform.ar_description": function (val) {
      this.formModal.ar_description = JSON.stringify(
        this.$refs.editformar_description.quill.getContents()
      );
    },
    "editform.en_description": function (val) {
      this.formModal.en_description = JSON.stringify(
        this.$refs.editformen_description.quill.getContents()
      );
    },
    "editform.skills": function (val) {
      this.formModal.skills = JSON.stringify(
        this.$refs.editformskills.quill.getContents()
      );
    },
    "form.ar_description": function (val) {
      this.formModal.ar_description = JSON.stringify(
        this.$refs.formar_description.quill.getContents()
      );
    },
    "form.en_description": function (val) {
      this.formModal.en_description = JSON.stringify(
        this.$refs.formen_description.quill.getContents()
      );
    },
    "form.skills": function (val) {
      this.formModal.skills = JSON.stringify(
        this.$refs.formskills.quill.getContents()
      );
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gR(), this.gSM();
  },
  methods: {
    async gSM() {
      await this.axios
        .get(
          `since/deparment?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&select=true`
        )
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    checkModel() {
      this.selected = [];
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      let data = new FormData();
      data.append("en_title", this.editform.en_title);
      data.append("ar_title", this.editform.ar_title);
      data.append("ar_description", this.editform.ar_description);
      data.append("en_description", this.editform.en_description);
      data.append("ar_sub_description", this.editform.ar_sub_description);
      data.append("en_sub_description", this.editform.en_sub_description);
      data.append("skills", this.editform.skills);
      data.append("image", this.editform.file);
      await this.axios
        .post(`job/${this.editform.id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    
    },
    async aNM() {
      let data = new FormData();
      data.append("en_title", this.form.en_title);
      data.append("ar_title", this.form.ar_title);
      data.append("ar_description", this.formModal.ar_description);
      data.append("en_description", this.formModal.en_description);
      data.append("ar_sub_description", this.form.ar_sub_description);
      data.append("en_sub_description", this.form.en_sub_description);
      data.append("skills", this.form.skills);
      data.append("image", this.form.file);
      await this.axios
        .post(`job`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      (this.form.en_title = ""),
        (this.form.ar_title = ""),
        (this.form.file = ""),
        (this.form.ar_description = ""),
        (this.form.en_description = ""),
        (this.form.skills = ""),
        (this.form.ar_sub_description = ""),
        (this.form.en_sub_description = "");
    },
    rEF() {
      (this.editform.en_title = ""),
        (this.editform.ar_title = ""),
        (this.editform.file = []),
        (this.editform.ar_description = ""),
        (this.editform.en_description = ""),
        (this.editform.skills = ""),
        (this.editform.ar_sub_description = ""),
        (this.editform.en_sub_description = ""),
        (this.editform.id = "");
    },
    async dM() {
      await this.axios
        .delete(`job/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      console.log(item);
      this.editform.en_title = item.en_title;
      this.editform.ar_title = item.ar_title;
      this.editform.ar_description = item.ar_description;
      this.editform.en_description = item.en_description;
      this.editform.skills = item.skills;
      this.editform.tags = item.tags;
      setTimeout(() => {
        if (this.editform.ar_description != null) {
          this.$refs.editformar_description.quill.setContents(
            JSON.parse(this.editform.ar_description).ops
          );
        }
        if (this.editform.en_description != null) {
          this.$refs.editformen_description.quill.setContents(
            JSON.parse(this.editform.en_description).ops
          );
        }
        if (this.editform.skills != null) {
          this.$refs.editformskills.quill.setContents(
            JSON.parse(this.editform.skills).ops
          );
        }
      }, 0);
      this.editform.ar_sub_description = item.ar_sub_description;
      this.editform.en_sub_description = item.en_sub_description;
      this.editform.id = item.id;
      console.log(this.editform)
    },
    dRM(item) {
      this.editform.ar_title = item.ar_title;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gR(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      
      await this.axios
        .get(
          `job?take=${this.perPage}&skip=${this.currentPage - 1}&title=${this.title}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = "";
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(`job?take=${this.perPage}&skip=${this.currentPage - 1}`)
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""), (this.department = "");
      this.gR();
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    selectFilesForForm(e) {
      this.form.files = [];
      console.log(e.target.files);
      console.log(e.target.files[0]);
      e.target.files.forEach((element) => this.form.files.push(element));
      console.log(this.form.files);
    },
    selectFilesForEditForm(e) {
      this.editform.files = [];
      console.log(e.target.files);
      console.log(e.target.files[0]);
      e.target.files.forEach((element) => this.editform.files.push(element));
      console.log(this.form.files);
    },
    selectFileForForm(e) {
      this.form.files = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.form.files = e.target.files[0];
      console.log(this.form.files);
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.editform.file = e.target.files[0];
      console.log(this.editform.file);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>